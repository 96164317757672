.range-edit-dialog {
    width: auto;
    min-height: 100px;
    min-width: 100px;
    max-width: 450px;

    font-size: 12px;

    border-style: solid;
    border-width: 1px;
    border-color: rgba(127, 127, 127, 0.7);
}

.range-selector {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.range-selector-columns {
    display: flex;
    flex-direction: row;
}

.range-selector-column {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
}

.range-selector-buttons {
    display: flex;
    flex-direction: row;
    margin: 15px;
}

.hide-column {
    visibility: hidden;
}

.range-selector-column .form-check-label {
    display: flex;
    align-items: center;
}