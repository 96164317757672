.siteCard {
    display: flex;
    flex-direction: column;

    border: 1px solid rgb(237, 235, 233);
    background-color: white;
}

.header {
    border-bottom: 1px solid rgb(237, 235, 233);
    padding: 0.75rem;

    .row1 {
        display: flex;
        flex-direction: row;
    
        > h6 {
            align-self: center;
            margin-bottom: 0;

        }
    
        .editButton {
            min-width: 2rem;
            height: 1.5rem;
        }
    }
    
    .row2 {
        font-weight: 400;
        color: rgb(151, 149, 147);
        font-size: 0.75rem;
        line-height: 1;
    }
}

.properties {
    display: flex;
    flex-direction: row;
    padding: 0.75rem;

    .column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .row {
        display: flex;
        flex-direction: row;
    }
}


