.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
}

.saveButton {
  max-width: 7rem !important;
  min-width: 7rem !important;
}
