.MetricsPage {
  flex-flow: column;
  align-self: flex-start;
  min-width: 100%;

  h2 {
    font-size: 1.2rem;
  }

  .buttons {
    flex-flow: row;
    margin-bottom: 1rem;
    margin-top: 1rem;
    .filters {
      flex-flow: column;
    }

    > div {
      flex: 1;
      max-width: 23rem;
    }

    .ui-dropdown__item__content {
      font-size: 0.875rem;
    }
  }

  .ui-dropdown {
    margin-right: 1rem;

    .ui-dropdown__container {
      background-color: white;
      width: auto;

      ul[role="listbox"] {
        width: 100%;
      }
    }
  }

  .date {
    align-self: flex-end;
  }

  .summary {
    display: flex;
    flex-flow: row wrap;
  }
  .inner {
    display: flex;
  }

  .summaryCard {
    flex: 0 0 auto;
    display: flex;
    flex-flow: column;
    border: 1px solid lightgray;
    padding: 1rem;
    padding-top: 0;
    margin: 1rem;
    margin-left: 0;
    width: 300px;
    height: 130px;
    box-shadow: 1px 1px 5px 0px #ccc;
    background: white;

    .firstLine {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 0.5rem;
      height: 100%;
      .icon {
        min-width: 2.5rem;
        min-height: 2.5rem;
        height: 40px;
      }
    }
    .value {
      margin: 1rem;
      font-size: 25px;
      color: #464676;
      font-weight: 600;
    }
    .valueDhm {
      margin: 1rem;
      font-size: 15px;
      color: #464676;
      font-weight: 600;
    }
    .title {
      font-size: 15px;
      font-weight: 500;
      color: gray;
    }
    .percentage {
      font-size: 20px;
      margin-left: 0.5rem;
      font-weight: 800;
    }

    .red,
    .green,
    .gray {
      display: flex;
      flex-flow: row nowrap;
      .arrow {
        width: 1rem;
        height: 1rem;
        align-self: center;
      }
    }
    .red {
      color: red;
    }
    .green {
      color: green;
    }
    .gray {
      color: gray;
    }
  }

  .graphs {
    display: flex;
    flex-flow: row;
    margin-top: 1rem;
    min-height: 0;
    height: 300px;
  }

  .graphs2 {
    display: flex;
    flex-flow: row;
    margin-top: 1rem;
    min-height: 0;
    height: 320px;
  }

  .barGraph {
    background-color: white;
    padding: 1rem;
    box-shadow: 1px 1px 5px 0px #ccc;
    flex: 5;
    min-width: 0;
    display: flex;
    flex-flow: column;
  }

  .timeTypeSelector {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    vertical-align: middle;
  }
  
  .graphContainer,
  .pieGraphContainer {
    position: relative;
    flex: 1;
  }
  .pieGraphContainer {
    max-width: 500px;
  }
  .pieGraph {
    min-width: 0;
    background-color: white;
    padding: 1rem;
    box-shadow: 1px 1px 5px 0px #ccc;
    flex: 4;
  }

  .spaceTop {
    margin-top: 1rem;
  }

  .spaceLeft {
    margin-left: 1rem;
  }

  .spaceBottom {
    margin-bottom: 1rem;
  }

  .allInAColumn {
    display: flex;
    flex-flow: column nowrap;
  }

  .allInARow {
    display: flex;
    flex-flow: row nowrap;
  }

  .downloadButton {
    width: 10rem;
  }
}
