.sharingCodesPage {
    :global(.ReactTable) :global(.rt-noData) {
        pointer-events: all !important;
    }

    h5 {
        margin-top: 2rem;
    }
}

.sharingCodeUsageForm {
    min-width: 500px;
}

.sharingCodeInput {
    display: flex;
}