.conferenceClientsPage {

    .statusEdit {
        display: flex;
        flex-flow: row;
    }

    .okcancel {
        margin-left: 0.5rem;
        display: flex;
        flex-flow: row;   
    }

    .ok {
        color: green;
    }

    .cancel {
        color: red;
    }

    div.status {
        overflow: visible;
    }
}