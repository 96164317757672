.testGraphProvider {
    display: flex;
    flex-flow: column;
}

.showProviderTestResult {
    margin-top: 2rem;
    .result {
        display: flex;
        flex-flow: row;
        margin-top: 0.2rem;

        .operation {
            min-width: 8rem;
            font-weight: 700;
        }
        .message {
            min-width: 32rem;
        }
        .detail {
            padding-left: 16px;
            > button {
                font-size: 0.8rem;
                text-align: end;
            
                background-color: transparent;
                border: none;
                cursor: pointer;
                display: inline;
                margin: 0;
                padding: 0;
                color: #00f;
                text-decoration: underline;
            }
        }
    }
}
