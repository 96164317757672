.BackgroundOperationProgressDialog {
  div.ui-dialog__content {
    width: 100%;

    .OperationProgressContent {
        margin-top: 1rem;
        margin-bottom: 0.75rem;
    }
  }
}
