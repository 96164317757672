.DevicesPage {
  .right-align {
    justify-content: flex-end;
  }

  .connectionInfo {
    display: flex;
    justify-content: flex-end;
  }
  .ui-toolbar__menu {
    z-index: 9;
  }
}
.DevicesPageMenu {
  .ui-popup__content__content {
    padding: 0;
    border: none;
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.leftMargin {
  margin-left: 0.5rem;
}

.gridContainer {
  height: 400px;
  overflow: scroll;
}

ReactTable {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}

.cellTextWrap {
  white-space: normal;
  overflow-wrap: break-word;
}

.panel {
  margin-top: 1rem;
}

.errorMessage {
  margin-left: 1rem;
  vertical-align: middle;
  margin-top: .25rem;
  color: red;
}