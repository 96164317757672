
legend rect {
    fill: rgba(0,0,0,0) !important;
    stroke-width: 0 !important;
  }
  
  legend text {
    fill: rgba(100,100,140,0.0) !important;
  }
  

.reportSection {
    background-color: white;
    padding: 1rem;
    box-shadow: 1px 1px 5px 0px #ccc;
    min-width: 100%;
    min-height: 20%;
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    
    .ReactTable {
        max-width: 50%;
        min-height: 20%;
        .rt-table {
            overflow: initial;
            width: auto;
        }

        .rt-thead.-headerGroups,
        .rt-thead.-header {
            background: #212529;
            color: #fff;
            border: 1px none #fff;
        }

        .rt-th {
            background: #212529;
            color: #fff;
            text-align: center;
            border: 1px none #fff;
            font-size: 0.75rem;
        }

        .rt-thead {
            .rt-th {
                position: relative;

                @mixin sort {
                    box-shadow: none;
                    &::after {
                        content: '';
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: auto;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 50%;
                        bottom: -15px;
                        margin: 5px 0;
                        transform: translateX(-50%);
                    }
                }

                &.-sort-asc {
                    @include sort;
                    &::after {
                        bottom: -25px;
                        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA1ElEQVR4nO3QSw4BQRRG4dMRYQfswcjYyC7Yg41ISEjsyMCymLRIPILWV3V1ny+5kxrV+UGSpFTGwCj1J1IogC1wLm9dvnVCARy4xV9vRwdGeBXfiRHexbd6hE/jWznCt/GtGqFqfCtG+DU+6xHqis9yhLrjsxohKj6LEaLjGz3Cv+IbOcK/4xs1Qqr4RoyQOj75CPsKn426TXDrg2lNH6/zJqHFd+ZBEb/cLLT4Th84BURUvSPQCy1+YggsgVXiWwCD4FZJkiRJkiRJkiRJkiRJmboA0ne+vLOLBScAAAAASUVORK5CYII=');
                    }
                }

                &.-sort-desc {
                    @include sort;
                    &::after {
                        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA5klEQVR4nO3XMU5CQRRG4UOChZS4Cis3QOVCcAsswsICEjdl455QC70JwUdQ3lxm3nvnS24/5+8GJEmSJEmSJEmSJEmSJKnbAngCNpVvDdwmt/5yA7wDn43cGzBPLT7ymBDR91apxUcekiL63H1qcYddoYeXuJfk1k4z4PWCx5a+7c9bqqg9QtX4UGuEJuLDtUdoKj5ca4Qm40P2CE3Hh6wRBhEfSo8wqPhQaoRBxoe+Iww6Plw6wijiw39HGFV8+OsIo4wP50YYdXw4NcIk4sOM7z/8B7AHnplQ/KE7YFn7EZKkqfoCG/K+ubWxD5IAAAAASUVORK5CYII=');
                    }
                }
            }
        }

        .rt-tr {
            align-items: stretch;
        }

        .rt-resizer {
            width: 5px;
            right: -4px;
        }
    }
}

.verticalSpace {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.summaryChartsContainer {
    display: flex;
    flex-direction: row;   
    justify-content: center;
    margin-top: 64px;
}

SeveritySelector {
    display: flex;
    flex-flow: column;
}

.timelineChart {
    overflow-x: auto;
    width: 100%;
    margin-top: 1rem;
}

:global(.legend rect) {
    fill: rgba(0,0,0,0) !important;
    stroke-width: 0 !important;
}

:global(.legend text) {
    fill: rgba(100,100,140,0.0) !important;
}

.pieGraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}



