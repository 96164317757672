.ReactTable .rt-thead .rt-tr {
  text-align: left;
}


.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  transition: all .3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}