.heatMap {
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  $width: 3rem;
  $labelWidth: 18rem;
  padding: 1rem;
  padding-right: 0;
  box-shadow: 1px 1px 5px 0px #ccc;
  
  .scrollContainer {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .heatMapTitle {
    display: flex;
    flex-flow: row nowrap;
  }

  .timeTypeSelector {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    vertical-align: middle;
  }
  
  .legend {
    display: flex;
    margin-left: auto;
    margin-right: 1rem;

    .color {
      margin-left: 5px;
      margin-right: 5px;
      background: linear-gradient(to right, #056707, #fc8b3f, #b20026);
      width: 6rem;
      height: 1rem;
    }
    .grayLegend {
      display: flex;
      margin-right: 10px;
      .gray {
        margin-left: 5px;
        margin-right: 5px;
        background: lightgray;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .grid {
    display: inline-flex;
    flex-flow: column;
  }

  .source {
    font-weight: 600;
    font-size: 12px;
    flex: 0 0 $labelWidth;

    position: sticky;
    left: 0;
    top: 0;
    background: white;
  }

  .group {
    margin-bottom: 1rem;  
  }

  .groupHeader {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    color: gray;
  }

  .rowHeader {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .hour {
    flex: 0 0 auto;
    width: $width;
    background: white;
    display: flex;
    justify-content: center;
  }

  .workingHours {
    flex: 0 0 auto;
    width: 8rem;
    background: white;
    display: flex;
    justify-content: center;
  }

  .heatMapRow {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 9;
    align-items: stretch;
  }

  .attendee {
    margin-right: auto;
    font-weight: 600;    
  }
  .room {
    display: flex;
    flex: 0 0 $labelWidth;
    position: sticky;
    left: 0;
    background: white;
    align-self: stretch;
    align-items: center;
    padding-right: 0.5rem;

    .icon {
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
    }

    .indicator {
      position: relative;
      $size: 2.7rem;
      width: $size;
      height: $size;

      .indicatorChart {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }

      .indicatorText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.6rem;
        font-weight: bold;
      }
    }
  }

  .duration {
    flex: 0 0 auto;
    border: 1px solid white;
    background-color: lightgrey;
    width: $width;
    height: $width;
  }
  .durationWorkingHours {
    flex: 0 0 auto;
    border: 1px solid white;
    background-color: lightgrey;
    width: 8rem;
    height: $width;
  }
}
