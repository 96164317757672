.DoughnutIndicator {
  transform: rotate(-90deg);
  circle {
    fill: transparent;
  }

  .background {
    stroke: #eee;
  }
}
