/* .room-card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
} */
.room-card-header {
    /* border: 1px solid rgba(0,0,0,0.125); */
    padding: .75rem 1.25rem;
    border-radius: .3rem .3rem 0 0;
    /* border-bottom: 1px solid rgba(0,0,0,.125); */
}

.room-card-header h5 {
    margin-bottom: 0px !important;
}

/* .room-card-content:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
} */
.room-card-content {
    /* border: 1px solid rgba(0,0,0,0.125); */
    padding: .75rem 1.25rem;
    border-radius: .3rem .3rem 0 0;
    /* border-bottom: 1px solid rgba(0,0,0,.125); */
}

.ui-header__description {
    font-size: 0.75rem !important; 
}

.edit-room-name {
    height: 1.5rem !important;
    min-width: 0 !important;
}


.push-end {
    margin-left: auto;
}

.card-body h6 {
    margin-bottom: 0.1rem;
    color: grey;
    font-weight: normal;
}

.card-body p {
    font-weight: bolder;
}

.change {
    font-size: 0.75rem;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    color: #007bff;
    text-decoration: none;
}

