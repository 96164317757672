.siteAttributes {
    display: flex;
    flex-grow: 1;


    .table {
        width: 100%;

        > tbody tr:nth-of-type(2n+1) {
            background-color: rgba(0,0,0,.05);
        }


        th, td {
            padding: 0.3rem;

        }
    }
}