.sectionTitle {
    font-size: 1.2rem;
    font-weight: 600;
}

.overlay {
    position: absolute;
    width: 97%;
    height: 67%;
    background-color: #FFFFFF;
    transition: opacity 500ms;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 16px;
}

