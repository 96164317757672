body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  /* margin-left: 20px; */
  overflow: hidden;
}

.ReactTable {
  background: white;
}

.ReactTable .-loading.-active > div {
  transform: translateY(-50%) !important;
}

.app {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.app main {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex: 1;
  overflow: auto;
  min-height: 0;
}

.scrollContainer {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  min-width: 100%;
  min-height: 100%;
}

.content {
  display: flex;
  flex: 1;
  align-items: stretch;
  min-height: 0;
}

.hidden{
  display:none;
}

.loader {
  margin-left: 500px
}

.feather {
  vertical-align: text-bottom;
}
 
.sidebar {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  min-width: 15rem;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sidebar svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  fill: #999;
}

.sidebar .nav-link span {
  flex: 1;
  display: flex;
}


.sidebar .nav-link .feather {
  margin-right: 8px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}


.sidebar .nav-link:hover svg {
  fill: #333;
}

.sidebar .nav-link.active svg{
  fill: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather{
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.sidebar-icon {
  float: left;
}

.expand-text {
  float: left;
  margin-bottom: 0;
}

 .expand-icon {
   float: right;
   margin-right: 2rem !important ;
}
