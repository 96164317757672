.sitePage {

    .pageHeader {
        background-color: #e9ecef;
        line-height: 2.5rem;
    }

    .breadcrumbs {
        display: flex;
        list-style-type: none;

        > li:not(:first-child)::before {
            padding-left: .25rem;
            padding-right: .25rem;
            color: #6c757d;
            content: "/";
        }
    }

}