
.fullscreen {
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

.icon {
    width: 8rem;
    height: 8rem;
    color: gray
}

.errorTitle {
    margin-top: 0.75rem;
    color: red;
    font-size: 1.5rem;
}
.errorText {
    margin-top: 0.25rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-right: 4rem;
    margin-left: 4rem;
}