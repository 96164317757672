.AddAttribute {
  .form-group {
    display: flex;
    flex-flow: column;
  }

 .deleteButton{
    border-color:red;
    color: red;
  }
  
  img {
   height:20px;
   width:20px;
   margin:0;
   padding:0; 
  }
}
