.bookingStats {
    padding-top: 1em;
    display: flex;
    flex-flow: column;
}

.highlights {
    padding: 0.25em;
    display: flex;
    align-items: center;
}

.highlightCancellationLabel {
    padding-left: 1em;
    padding-top: 0.35em;
}

.highlightDropdown {
    padding-left: 0.5em;
}

.organiserStatsReport {
    background-color: white;
    padding: 1rem;
    box-shadow: 1px 1px 5px 0px #ccc;
    min-width: 0;

    .reportIconWrapper {
        display:inline-block;
        .reportIcons {
            height: 22px;
            width: 22px;
            padding-left: 4px;
        }
    }
}

.centerAlign {
    text-align: center;
}

.bookingStatsReport {
    background-color: white;
    padding: 1rem;
    box-shadow: 1px 1px 5px 0px #ccc;
    margin-top: 1rem;
    min-width: 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .ReactTable {
        min-width: 100%;

        .rt-table {
            overflow: initial;
            width: auto;
        }

        .rt-thead.-headerGroups {
            background: #212529;
            color: #fff;
            border: 1px none #fff;
        }

        .rt-thead.-header {
            background: #212529;
            color: #fff;
            border: 1px none #fff;
        }

        .rt-th {
            background: #212529;
            color: #fff;
            text-align: center;
            border: 1px none #fff;
            font-size: 0.75rem;
        }

        .rt-thead {
            .rt-th {
                position: relative;
                @mixin sort {
                    box-shadow: none;
                    &::after {
                        content: '';
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: auto;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 50%;
                        bottom: -15px;
                        margin: 5px 0;
                        transform: translateX(-50%);
                    }
                }

                &.-sort-asc {
                    @include sort;
                    &::after {
                        bottom: -25px;
                        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA1ElEQVR4nO3QSw4BQRRG4dMRYQfswcjYyC7Yg41ISEjsyMCymLRIPILWV3V1ny+5kxrV+UGSpFTGwCj1J1IogC1wLm9dvnVCARy4xV9vRwdGeBXfiRHexbd6hE/jWznCt/GtGqFqfCtG+DU+6xHqis9yhLrjsxohKj6LEaLjGz3Cv+IbOcK/4xs1Qqr4RoyQOj75CPsKn426TXDrg2lNH6/zJqHFd+ZBEb/cLLT4Th84BURUvSPQCy1+YggsgVXiWwCD4FZJkiRJkiRJkiRJkiRJmboA0ne+vLOLBScAAAAASUVORK5CYII=');
                    }
                }

                &.-sort-desc {
                    @include sort;
                    &::after {
                        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA5klEQVR4nO3XMU5CQRRG4UOChZS4Cis3QOVCcAsswsICEjdl455QC70JwUdQ3lxm3nvnS24/5+8GJEmSJEmSJEmSJEmSJKnbAngCNpVvDdwmt/5yA7wDn43cGzBPLT7ymBDR91apxUcekiL63H1qcYddoYeXuJfk1k4z4PWCx5a+7c9bqqg9QtX4UGuEJuLDtUdoKj5ca4Qm40P2CE3Hh6wRBhEfSo8wqPhQaoRBxoe+Iww6Plw6wijiw39HGFV8+OsIo4wP50YYdXw4NcIk4sOM7z/8B7AHnplQ/KE7YFn7EZKkqfoCG/K+ubWxD5IAAAAASUVORK5CYII=');
                    }
                }
            }
        }

        .rt-tr {
            align-items: stretch;
        }

        .rt-resizer {
            width: 5px;
            right: -4px;
        }

        .organiserHeader {
            text-align: left;
            .highlightHeader {
                display: flex;
                align-items: center;
                height: 32px;
                opacity: 0.5;
            }
        }

        .rt-resizable-header.headerWithHighlight {
            .ui-dropdown__container {
                margin-right: 5px;
            }

            &:last-child {
                overflow: visible;

                .rt-resizer {
                    display: none;
                }
            }
        }

        .ui-dropdown__toggle-indicator {
            background-color:#DDD;
            height: 16px;   
            margin-right: 35%; 
            width: 16px;             
        }        

       
    }
}

.highlight {
    font-weight: bold;
    color: red;
}

.headerWithHighlight > div {
    display: flex;
    flex-flow: column;
    
    .ui-dropdown {
        margin-right: 0;
        height: 32px;
        
        &.noCaret {
            .ui-dropdown__toggle-indicator {
                display: none;
            }
        }

      

        .ui-dropdown__container {
            background: none;
            left: 0;
            right: 0;
            position: absolute;

            .ui-dropdown__selected-items {
                padding: 0;
            }

            .ui-list__item__content {
                margin-right: 0;
            }

            button {
                min-width: 0;
                width: 100%;
                color: white;
                justify-content: center;

                span {
                    font-size: inherit;
                    font-weight: inherit;
                }
            }

            svg {
                color: white;
            }

            li {
                padding: 4px 2px;
            }
        }
    }
}

.dropdownHeader {
    display: flex;
   
}
