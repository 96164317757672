  .ReactTable .rt-thead.-header {
    background: #212529;
    color: #fff
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #007bff
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -2px 0 0 #007bff
}

.panel-button {
  width: 35px;
  height: 35px;
}