.add-room-form {
}

.add-room-form h5 {
    margin-bottom: 1em
}

.add-room-form-field > .ui-box {
    width: 100%;
}

.add-room-form-field > .ui-box input {
    width: 100%;
}

form.add-room-form  {
    height: auto;
}