.Picker {
    display: flex;
    flex-flow: column;

    .Header {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
    }

    .PickerList {
        display: flex;
        flex-flow: column;

        list-style: none;
        padding: 0;
        min-height: 0;
        max-height: 15rem;
        overflow: auto;

        border-color: rgb(221, 221, 221);
        border-width: 1px 1px 1px 1px;
        border-style: solid;

        > li {
            display: flex;
            flex-flow: row;

            .Action {
                margin-left: auto;
                visibility: hidden;
            }
            &:hover {
                .Action {
                    visibility: visible;
                }
            }
        }
    }
}
