.RoomsPendingDialog {
  min-width: 300px;
  ul {
    list-style-type: none;
  }
}
.WindowsUpdateCardRoot {
  .WindowsUpdateCard {
    padding: 1rem;
  }
}
