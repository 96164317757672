.logo {
        height: 35px;
}


.content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 1rem;
}

.text {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
}