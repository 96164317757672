.AddBookItRoom {
  .ui-dialog_content {
    width: 1500px;
  }

  .ui-form {
    width: 1500px;
  }

  .floorPlan {
    height: 70px;
    width: 100px;
  }

  .formColumn {
    > * {
      margin-bottom: 1rem;
    }
  }

  .buttons {
    margin-top: 1rem;
  }

  .attributes {
    display: flex;
    flex-flow: row wrap;

    .selected {
      border: 1px blue solid;
    }

    .attribute {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      margin: 20px;
      cursor: pointer;

      svg {
        height: 30px;
        width: 30px;
      }

      img {
        margin: 0px;
        height: 30px;
        width: 30px;
      }
    }

    .label {
      color: gray;
      font-size: 8px;
    }
  }

  .location {
    > * + * {
      margin-left: 1rem;
    }
  }
}
