
.signedOut {
    height: 100vh;
    display:flex;
    flex-direction: column;
    padding: 2rem;
    // justify-content: center;
    // text-align: center;
    // align-items: center;
  }

.icon {
    width: 8rem;
    height: 8rem;
    color: gray
}

.title {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    color: #6264A7;

}
