.AttributesPage {
  flex-flow: column;
 .attributes {
    display: flex;
    flex-flow: row wrap;

    .attribute {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      margin: 20px;

      svg {
        height: 30px;
        width: 30px;
      }

      img {
        margin: 0px;
        height: 30px;
        width: 30px;
      }
    }

    .label {
      color: gray;
      font-size: 11px;
    }
  }

}
