.screenshot-thumbnail {
    max-width: 320px;
    max-height: 180px;
    min-width: 320px;
    min-height: 180px; 
    display: flex;
    justify-content: center;
    align-items: center; 
}

.screenshots-container {
    display: flex;
    flex-direction: row;
}

.thumbnail-content {
    display: flex;
    flex-direction: column;
    margin: 0.4em;
}