.WindowsUpdateTable {
  .WindowsUpdateTableHeader {
    padding: 0.75rem 1.25rem;
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .WindowsUpdateItems {
    list-style-type: none;
    padding-left: 0;
    .WindowsUpdateItem{
        cursor: pointer;
    }
    .WindowsUpdateItemSelected {
        border-left: 5px solid rgb(98, 100, 167);
    }
  }

  .ToolbarItem {
    margin-left: 0.25rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
