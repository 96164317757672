.header {
    display: flex;
    flex-direction: row;
    height: 54px;
    align-items: center;
    background-color: rgb(0, 120, 212);
}

.title {
    display: flex;
    margin-left: 1rem;
    span {
        margin-left: 1rem;
        color: white;
        font-size: 1.6em;
    }
    svg {
        height: 35px;
    }
}

.orgSelector {
    margin-left: 2rem;
}

.login {
    margin-left: auto;
    margin-right: 1rem;
}

.popup {
    padding: 0 !important;
}

.popupContent {
    :global(.ui-popup__content__content) {
        padding-left: 0;
        padding-right: 0;
    }
    margin-right: 1rem;
}

.popupMenu {
    width: 8rem !important;
    border-left-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
    border-bottom-style: none !important;
}
