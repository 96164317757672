.settingsSection {
    div {
        display: flex;
    }
    display: flex;
    flex-direction: column;
    width: 100%;

    .settingsSectionBody {
        margin-top: 0.5rem;
        flex-direction: column;
        flex-grow: 1;

        .setting {
            flex-direction: column;
            margin-bottom: 2px;
            background: white;
            padding: 1rem;

            .settingHeader {
                margin-bottom: 0.75rem;

                .settingHeaderDesc {
                    flex-direction: column;
                    margin-right: auto;
                }
            }
            
            .settingOverride {
                display: flex;
                flex-direction: row;

                .settingOverrideDesc {
                    color: red;
                    margin-right: 0.5rem;
                    line-height: 1.5rem;
                    min-width: 15rem;
                }

                .overrideCheckbox {
                    margin-left: 0.5rem;
                }
            }

            .settingChildren {
                flex-direction: column;
                align-items: flex-end;

                .settingInput {
                    flex-direction: row;
                    align-items: center;
                    label {
                        width: 25%;
                        white-space: nowrap;
                    }
                }

                .toggleLabel {
                    margin-right: auto;
                }

                .settingInputAndError {
                    width: 100%;
                    flex-direction: column;
                }

                > * {
                    width: 50%;
                    margin-bottom: 0.4rem;
                }
            }
        }
    }
}
