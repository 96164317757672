.supportSettings {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    min-height: 0;
}

.selectorCard {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0.5rem;
    width: 20%;
    border-style: solid;
    border-width: 1px;
    border-color: darkgray;
    border-radius: 4px;
    padding: 1rem;
    overflow-y: auto;
}

.menuBar {
    display:flex;
    flex-direction: column;
    margin-top: .5rem;
    margin-left: 1rem;
    :global(.ui-button) {
        justify-content:left;
    }
}

.menuButton {
    width: 13rem;
    margin-bottom: .5rem;
    
    
}

.horizontalContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.verticalContainer {
    display: flex;
    flex-direction: column;
}

.infoText {
   font-style: italic;
   margin-bottom: 1rem; 
   height: 4rem;
   border-bottom-width: 1px;
   border-bottom-color: darkgrey;
   border-bottom-style: solid;
   color:darkgrey;
}

.dropDown {
    margin-bottom: 1rem;
}

.userList {
    background-color: transparent;

    :global(.ui-list__itemcontent) {
        font-size: 1rem;
    }
}

.highlight {
    font-weight: 700;
}

