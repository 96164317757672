.calendarDelegationContent {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
    }


    .radioGroup {
        margin-right: 3rem;
    }

    .emailList {
        min-width: 30rem;
    }

    .addButton {
        margin-top: 1.25rem;
        margin-left: 1rem;
    }
}