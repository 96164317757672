.ReportsPage {
    flex-flow: column;
    align-self: flex-start;
    min-width: 100%;

    h2 {
        font-size: 1.2rem;
    }

    .buttons {
        flex-flow: row;
        margin-bottom: 1rem;
        margin-top: 1rem;
        
        > div {
            flex: 1;
            max-width: 23rem;
        }

        .ui-dropdown__item__content {
          font-size: 0.875rem;
        }
      }

      .ui-dropdown {
        margin-right: 1rem;
    
        .ui-dropdown__container {
          background-color: white;
          width: auto;
    
          ul[role="listbox"] {
            width: 100%;
          }
        }
      }
    
      .date {
        align-self: flex-end;
      }
    
}