.DateSelector{
  .options{
    display:flex;
    border-right: gray solid 1px;
    flex-flow:row;
    margin-right:1rem;
    padding-right:1rem;
  }
 .checks{
   display:flex;
   flex-flow:column;
 }

 .dates{
   display:flex;
   justify-content: space-evenly;
   margin-bottom: 1rem;
 }
.react-datepicker{
  border-radius:0
}
 .react-datepicker__month-container{
   border: 1px solid gray
 }
 .range-selector-buttons{
   justify-content: flex-end;
 }

}