.toast {
    display: flex;
    padding: 0.25rem;
}

.toast header {
    font-weight: 550;
    font-size: 1.1rem;
    margin-bottom: 0.4rem
}

.toast-icon {
    margin-right: 0.5rem
}

.toast-contents {
    flex: 1;
}

.toast-message {
    font-size: 0.8rem
}

.toast-problem-title {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.toast-problem-detail {
    font-size: 0.7rem;
    margin-bottom: 0rem;
}

.toast-problem-content {
    display: flex;
    flex-flow: column;
}

.toast-problem-show-detail {
    font-size: 0.7rem;
    text-align: end;

    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #fff;
    text-decoration: none;
}

.toast-problem-show-detail:hover, 
.toast-problem-show-detail:focus {
    text-decoration: underline;
    outline: none !important;
}
.toast-problem-detail {
    margin-bottom: 1em;
}

.toast-problem-details {
    display: flex;
    flex-direction: row;
    margin-top: 0.2rem;
    background-color: #e74c3c;
}
.toast-problem-details p {
    margin-bottom: 0 !important;
}

.toast-problem-details-headings {
    margin-right: 0.3em;
}
.toast-problem-details-headings p {
    font-size: 0.7rem;
    font-weight: 600;
}

.toast-problem-details-data {
    flex: 1;
    font-size: 0.7rem;
}

.toast-problem-details-data p {
    font-size: 0.7rem;
}

.Toastify__close-button {
    margin-right: 0.4rem;
}

.Toastify__toast {
    border-radius: 8px;
}

.Toastify__toast-container {
    width: 420px;
}
