
.topLevel {
    >li:first-child {
        margin-top: 1rem;
    }
    >li:last-child {
        padding-bottom: 2rem;
    }
}

.productLogo {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    height: 2.2rem;
}

