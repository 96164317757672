.GraphApiPage {
    display: block;
    background-color: #ffff;
    padding: 1rem;
    
    .graph_api {
      width: 1100px;
      height: 600px;
      margin: 0px;
    }

    .graph_api_dropdown {
      margin: 2px;
    }

    .inputtext {
      width: 50px;
      text-align: right;
      margin: 2px;
    }

    .inputtext_filter {
      width: 250px;
      text-align: left;
      margin: 2px;
    }
  }
  